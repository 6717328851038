export default [
    {
        path: '/coberturas/:id',
        name: 'coberturas',
        props:true,
        component: () => import('@/views/coverages/Coverages.vue'),
        meta: {
            action: 'read',
            resource: 'Ver roles',
            pageTitle: 'Coberturas',
            breadcrumb: [
                {
                    text: 'Coberturas',
                    active: true,
                },
            ],
        },
    },
]
