export default [
  {
    path: '/cotizations/new-cotization',
    name: 'new-cotization',
    component: () => import('@/views/cotizations/new-cotization/NewCotization.vue'),
    meta: {
      resource: 'cotization.list',
      action: 'read',
      pageTitle: 'Cotización',
      breadcrumb: [
        {
          text: 'Cotización',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cotizations/vaucher-history',
    name: 'vaucher-history',
    component: () => import('@/views/cotizations/vaucher-history/VaucherHistory.vue'),
    meta: {
      resource: 'cotization.list',
      action: 'read',
      pageTitle: 'Historial de vaucher',
      breadcrumb: [
        {
          text: 'Historial de vaucher',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cotizations/cotization-history',
    name: 'cotization-history',
    component: () => import('@/views/cotizations/cotization-history/CotizationHistory.vue'),
    meta: {
      resource: 'cotization.list',
      action: 'read',
      pageTitle: 'Historial de cotizaciones',
      breadcrumb: [
        {
          text: 'Historial de cotizaciones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cotizations/voucher/asegurate/:id',
    name: 'voucher-asegurate',
    component: () => import('@/views/cotizations/vaucher-history/VoucherAsegurate.vue'),
    meta: {
      resource: 'cotization.list',
      action: 'read',
      pageTitle: 'Aseguradores',
      breadcrumb: [
        {
          text: 'aseguradores',
          active: true,
        },
      ],
    },
  },
]
