export default [
    {
        path: '/modalities',
        name: 'modalities',
        component: () => import('@/views/modalities/Modalities.vue'),
        meta: {
            action: 'read',
            resource: 'modalities.list',
            pageTitle: 'Modalidades',
            breadcrumb: [
                {
                    text: 'Planes Coberturas',
                    active: true,
                },
            ],
        },
    },
]
