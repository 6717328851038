export default [
    {
        path: '/historic',
        name: 'historic',
        component: () => import('@/views/historic/Historic.vue'),
        meta: {
            action: 'read',
            resource: 'Second',
            pageTitle: 'Historico',
            breadcrumb: [
                {
                    text: 'Historico',
                    active: true,
                },
            ],
        },
    },
]
