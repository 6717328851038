export default [
  {
    path: '/plans',
    name: 'plans',
    component: () => import('@/views/plans/Plans.vue'),
    meta: {
      resource: 'plans.list',
      action: 'read',
      pageTitle: 'Planes',
      breadcrumb: [
        {
          text: 'Planes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/plans/view/:id',
    name: 'plans-view',
    component: () => import('@/views/plans/components/Rates.vue'),
    meta: {
      resource: 'Ver roles',
      action: 'read',
      pageTitle: 'Tarifas',
      breadcrumb: [
        {
          text: 'Tarifas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/countries/:id',
    name: 'countries',
    component: () => import('@/views/plans/components/Country.vue'),
    meta: {
      resource: 'Ver roles',
      action: 'read',
      pageTitle: 'Destinos',
      breadcrumb: [
        {
          text: 'Destinos',
          active: true,
        },
      ],
    },
  },
]
