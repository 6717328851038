export default [
  {
    path: '/agencies',
    name: 'agencies',
    component: () => import('@/views/agencies/Agencies.vue'),
    meta: {
      resource: 'agencies.list',
      action: 'read',
      pageTitle: 'Agencias',
      breadcrumb: [
        {
          text: 'Agencias',
          active: true,
        },
      ],
    },
  },
]