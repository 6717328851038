export default [
      {
            path: '/reports',
            name: 'reports',
            component: () => import('@/views/reports/ReportSales.vue'),
            meta: {
                  resource: 'reports.all',
                  action: 'read',
                  pageTitle: 'Reportes'
            },
      },
]